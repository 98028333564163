// @import './reset.css';

@import "~antd/dist/antd.less";

@import "//at.alicdn.com/t/c/font_3823694_wqnzyac7afq.css";

@import url('https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300;500;600;700&display=swap');

@font-family: 'Albert Sans', 'SF Pro Text', arial, sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';


// breakpoint width
//     xs: '480px',
//     sm: '576px',
//     md: '768px',
//     lg: '992px',
//     xl: '1200px',
//     xxl: '1600px',
.mainBg {
  
  border: 1px solid #ffffff;
}

body {
  padding: 0;
  margin: 0;
  font-family: @font-family;
  font-weight: 500;
}

//antd 全局覆盖样式
.ant-btn {
  // padding: 0;
  border: none;
  border-radius: 22px;
}

.mainTableNav {
  
  border: 1px solid #ffffff;
  border-radius: 6px;
  padding: 16px;

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #385e9d;
    padding-bottom: 2px;
    border-bottom: 1px solid #385e9d;
  }

  .table {
    margin-top: 23px;
  }
}

.man-btn {
  .ant-btn {
    // padding: 0;
    border: none;
    border-radius: 22px;
    background: #ec7f25;
  }
}

.btn-234 {
    border-radius: 8px;
    background-color: #ec7f25;
    padding: 5px 44px;
    font-size: 14px;
    color: #fff;
    height: 33px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-234-secondary {
  .btn-234;
  background-color: #F1974D;
  
}

.ant-btn {
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
}


.input-234 {
  border-radius: 10px;
  height: 42px;
  border-width: 1.5px;
  border-color: #bababa;
  font-size: 13px;

  &::placeholder {
    color: #6f6f6f;
  }
}


.form-item-234 {
  font-size: 14px;
  font-weight: 600;

  input {
    .input-234;
  }

  textarea {
    .input-234;
    height: 156px;
  }

  .ant-input-number {
    .input-234;
  }

}

.form-item-235 {
  font-size: 14px;
  font-weight: 500;

  input {
    border-radius: 10px;
    height: 42px;
    border-width: 1.3px;
    border-color: #bababa;
    font-size: 13px;
  
    &::placeholder {
      color: #6f6f6f;
    }
  }

  textarea {
    border-radius: 10px;
    height: 85px;
    border-width: 1.3px;
    border-color: #bababa;
    font-size: 13px;
  
    &::placeholder {
      color: #6f6f6f;
    }
  }

}

.delete-modal-234 {
  
  .ant-modal-content {
    border-radius: 30px;
    border: #B1B1B1 1.5px solid;
  }
  .ant-modal-body {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    padding-top: 25px;
    
    .ant-modal-confirm-btns {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
      >:first-child {
        width: 120px;
        background-color: #EC7F25;
        border: #EC7F25 1px solid;
        color: #fff;
        margin-left: 10px;
      }
      >:last-child {
        width: 120px;
        border: #BDBDBD 1px solid;
        background-color: #fff;
        color: #525252;
        margin-right: 10px;
      }
    }
  }

}

.table-234 {
  min-height: 250px;
  .ant-table-thead > tr > th {
    background-color: #f2f6f7;   
    font-size: 12px; 
  }
}

.modal-234 {
  .ant-modal-content {
    border-radius: 30px;
  }
  .ant-modal-header {
    border-radius: 30px 30px 0 0;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
    border-bottom: #fff;
  }
  .ant-modal-body {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0;
    padding-top: 0;
  }
  .ant-modal-footer {
    border-top: 0;
    border-radius: 0 0 30px 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 30px;
  }
}


// .actions {
//   .action {
//     padding: 2px 10px;

//     background: #f2f2f2;
//     border-radius: 25px;
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 24px;
//     letter-spacing: -0.31px;
//     color: #656565;
//     border: none;
//     &.primary {
//       background: rgba(255, 120, 45, 0.1);
//       color: #FF782D;
//     }
//     &:last-child {
//       margin-left: 17px;
//     }
//   }
// }
.ant-table {
  font-size: 12px !important;
}
.ant-list{
  font-size: 12px !important;
}
@primary-color: #ec7f25;